<script setup lang="ts">
import { FlightSearchHistory } from '#types/flight';
import { HotelSuggestion } from '#types/hotel';
import { ref, useTracker, useRuntimeConfig } from '#imports';

interface IProps {
  initialSearchParams?: {
    flight?: FlightSearchHistory;
    hotel?: {
      destination?: Pick<HotelSuggestion, 'type' | 'id' | 'name' | 'city'>;
    };
  };
  initialActiveTab?: 'flight' | 'hotel';
  type?: 'flight' | 'hotel';
  showOrderListButton?: boolean;
  wrapperClass?: string;
}

const props = withDefaults(defineProps<IProps>(), {
  initialActiveTab: 'flight',
});

const runtimeConfig = useRuntimeConfig();
const { gtagEvent, jitsuEvent } = useTracker();

const activeTab = ref<'flight' | 'hotel'>(props.initialActiveTab);

function onSubmit(data: FlightSearchHistory) {
  const { depAirport, arrAirport } = data;

  const origin = normalizeDDRAirport(depAirport);
  const destination = normalizeDDRAirport(arrAirport);

  gtagEvent('view_search_results', {
    send_to: runtimeConfig.public.gtagId,
    items: [
      {
        origin,
        destination,
        google_business_vertical: 'flights',
      },
    ],
  });

  jitsuEvent('view-search-results', {
    origin,
    destination,
  });
}

function onSwitchSearchForm(type: string) {
  if (type === 'flight') {
    activeTab.value = 'flight';
    jitsuEvent('user-switch-button', {
      event_name: 'switch-to-searchform-flight',
      object_name: 'search-form',
      object_parameter: 'product',
      product: 'flight',
    });
  } else {
    activeTab.value = 'hotel';
    jitsuEvent('user-switch-button', {
      event_name: 'switch-to-searchform-hotel',
      object_name: 'search-form',
      object_parameter: 'product',
      product: 'hotel',
    });
  }
}
</script>

<template>
  <div>
    <ul class="flex ml-20">
      <li
        :class="{ '!text-opacity-100 rounded-t bg-primary-dark bg-opacity-60': activeTab == 'flight' }"
        data-testid="product-flight-button"
        class="px-20 py-15 text-white text-medium font-bold text-opacity-50 mix-blend-normal cursor-pointer hover:text-opacity-100"
        @click="onSwitchSearchForm('flight')"
      >
        <ApzIcon
          :icon="['fak', 'flight']"
          class="mr-5"
        />

        {{ $t('global.flight') }}
      </li>
      <li
        :class="{ '!text-opacity-100 rounded-t bg-primary-dark bg-opacity-60': activeTab == 'hotel' }"
        data-testid="product-hotel-button"
        class="px-20 py-15 text-white text-medium font-bold text-opacity-50 mix-blend-normal cursor-pointer hover:text-opacity-100"
        @click="onSwitchSearchForm('hotel')"
      >
        <ApzIcon
          :icon="['fak', 'hotel']"
          class="mr-5"
        />

        {{ $t('global.hotel') }}
      </li>
    </ul>
  </div>

  <div :class="wrapperClass">
    <KeepAlive>
      <FlightSearchForm
        v-if="activeTab === 'flight'"
        :initial-search-params="initialSearchParams?.flight"
        :show-order-list-button="showOrderListButton"
        :class="$attrs.class"
        @submit="onSubmit"
      />

      <LazyHotelSearchForm
        v-else
        :initial-search-params="initialSearchParams?.hotel"
        :show-order-list-button="showOrderListButton"
        :class="$attrs.class"
      />
    </KeepAlive>
  </div>
</template>
